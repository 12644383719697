import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { deleteOrder, downloadCustomerPdf, getOrdersV2 } from '../../../store/order-actions';
import ConfirmationDialog from '../../../UI/Alert/ConfirmDialog';

import { OrderConstant } from '../../../Constants';

const OrderTable = ({ orders }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //This page is the total returned size page.
    const currentRequestPage = useSelector(state => state.order.currentRequestPage);
    const totalOrderCount = useSelector(state => state.order.totalOrderCount);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(OrderConstant.RowsPerPage[0]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const open = Boolean(anchorEl);

    // useEffect(() => {
    //     //console.log(`Page: ${page}, Row Per Page: ${rowsPerPage}`)
    //     if((page+1)*rowsPerPage >= orders.length){
    //         //console.log('time to download')
    //     }
    // },[page, rowsPerPage])

    //useEffect(() => {console.log(`Total orders: ${totalOrderCount}`)}, [totalOrderCount])

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const handleClick = (event, order) => {
        setSelectedOrder(order);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        console.log(`handleChangePage ${newPage}`);
        const startIndex = newPage * rowsPerPage;

        //const reqSize = OrderConstant.MaxOrderRequestLimit;

        // Check if we need to fetch more orders
        if (startIndex >= orders.length && orders.length < totalOrderCount) {
            //fetch next page from api
            console.log(`handleChangePage ${newPage}. Need request more`);
            //dispatch(updateOrderV2RequestPage(newPage));
            dispatch(getOrdersV2(currentRequestPage + 1))
        }

        //Table page and request page is different.
        //If my requested order is 100 and my row per page is 25, I would have 4 table page.

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(`handleChangeRowsPerPage: ${+event.target.value}`)
        const newRowCount = +event.target.value;
        setRowsPerPage(newRowCount);
        setPage(0);

        //set limit
        //dispatch(updateOrderV2Limit(newRowCount))
    };

    const getPaymentStatusStyle = (status) => {
        const style = {
            whiteSpace: 'nowrap',
            borderRadius: '3px',
            padding: '0 4px',
            textAlign: 'center',
            verticalAlign: 'middle',
            fontSize: '0.8125rem',
            lineHeight: '1.25rem',
            alignSelf: 'flex-end',
        }

        switch (status) {
            case 'Awaiting Payment':
                style.backgroundColor = '#a6d3bb';
                style.boxShadow = 'inset 0 0 0 1px #80c19e';
                style.color = '#002e15';
                break;
            case 'Partial Payment':
                style.backgroundColor = '#FFE699';
                style.boxShadow = 'inset 0 0 0 1px #BF8F00';
                style.color = '#BF8F00';
                break;
            case 'To Invoice':
                style.backgroundColor = '#DEEBF7';
                style.boxShadow = 'inset 0 0 0 1px #4472A9';
                style.color = '#4472A9';
                break;
            case 'Overdue':
                style.backgroundColor = '#FFCBCB';
                style.boxShadow = 'inset 0 0 0 1px #FF2F00';
                style.color = '#FF2F00';
                break;
            default:
                style.backgroundColor = '#fff';
                style.boxShadow = 'inset 0 0 0 1px rgba(0,10,30,0.5)';
                style.color = 'rgba(0,10,30,0.75)';
        }
        return style;
    }

    const getChipStyle = (status) => {

        var backgroundColor = null;
        var color = 'black';

        switch (status) {
            case 'Approved':
                backgroundColor = 'rgb(69,129,62)';
                color = 'white';
                break;
            case 'Sent':
                backgroundColor = '#4472C4';
                color = 'white';
                break;
            case 'Cancelled':
                backgroundColor = 'red';
                color = 'white';
                break;
            default:
                backgroundColor = null;
                color = 'black';
        }

        //const backgroundColor = status === 'Sent' ? 'rgb(69,129,62)' : status === 'Cancelled' ? 'red' : null;
        //const color = status === 'Sent' || status === 'Cancelled' ? 'white' : null;
        return { backgroundColor, color };
    }

    const onDeleteOrderClicked = () => {
        setShowDeleteDialog(true);
    }

    const onDeleteOrder = () => {
        setShowDeleteDialog(false);

        dispatch(deleteOrder(selectedOrder._id))

        setSelectedOrder(null);
    }

    const columns = [
        { id: 'orderno', label: 'Order No', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'invoiceno', label: 'Invoice No', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'salesrep', label: 'Sales Rep', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'client', label: 'Client', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'orderdate', label: 'Order Date', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'amount', label: 'Amount', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' }, format: (val) => `$${val.toFixed(2)}` },
        { id: 'status', label: 'Status', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
        { id: 'payment', label: 'Payment', style: { minWidth: 20 } },
        { id: 'actions', label: 'Actions', style: { minWidth: 20, fontSize: '0.8rem', whiteSpace: 'nowrap' } },
    ]

    const rows = orders.map(order => {
        return {
            orderid: order._id,
            orderno: order.orderNo,
            invoiceno: order.invoiceNo,
            salesrep: order.salesrep,
            client: <span>{order.client.companyName} <br /> {order.client.clientName + ' - ' + order.client.contact}</span>,
            orderdate: dayjs(order.createdAt).format('DD/MM/YY'),
            amount: order.totalAmtBeforeGST,
            status: <Chip label={order.status} variant="filled" sx={{ ...getChipStyle(order.status) }} />,
            payment: <div style={{ display: 'flex' }}>
                {
                    order.status !== 'Cancelled' ? 
                    <>
                        <span style={getPaymentStatusStyle(order.paymentStatus)}>{order.paymentStatus}</span>
                        <div style={{ flex: '1 1 auto' }}></div>
                    </> : <span>-</span>
                }
            </div>,
            actions: <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e, order);
                }}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <MoreHorizOutlined sx={{ width: 32, height: 32 }} />
            </IconButton>
        }
    })

    return (
        <>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ ...column.style }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={row.orderno} onClick={() => { navigate(`order/${row.orderid}`) }}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={OrderConstant.RowsPerPage}
                component="div"
                //count={rows.length}
                //totalOrderCount
                count={totalOrderCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => { navigate(`order/${selectedOrder._id}`) }}>
                    View Order
                </MenuItem>
                <MenuItem onClick={() => dispatch(downloadCustomerPdf(selectedOrder._id))}>
                    Download PDF
                </MenuItem>
                <MenuItem sx={{ color: 'red' }} onClick={onDeleteOrderClicked}>
                    Delete
                </MenuItem>
            </Menu>

            <ConfirmationDialog
                open={showDeleteDialog}
                onCancel={() => { setShowDeleteDialog(false) }}
                onAccept={onDeleteOrder}
                title="Delete Confirmation"
                acceptButtonText="Delete"
                content={`Confirm delete ${selectedOrder?.orderNo}?`}
            />
        </>
    )
}

export default OrderTable